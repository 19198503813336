import React from "react";
import { Route, Routes } from "react-router-dom";

import routes from "./routes";

const routeCombiner = () => {
  const RoutesMap = routes.map(({ exact = true, Layout = ({ children }) => <>
        {children}
      </>, Component, path, label, title }) => {
    const ComponentWithLayout = () => {
      return (
        <Layout title={title ? title : label}>
          <Component />
        </Layout>
      );
    };
    return [
      <Route
        key={path}
        exact={exact}
        element={<ComponentWithLayout />}
        path={path}
      />,
    ];
  });
  return <Routes>{RoutesMap}</Routes>;
};

export default routeCombiner;
