import create from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";

var hash = require("object-hash");

let useStore = (set, get) => ({
  dataHash: null,
  dataTimestamp: 0,
  dataLoaded: false,
  dataRefresh: true,
  dataLoading: false,
  data: {},
  selectedLocale: {},
  colorScheme: "light",

  getData: async () => {
    set({
      dataLoading: true,
      dataRefresh: false,
    });
    const response = await axios.get("/content.json");
    set({
      data: response.data,
    });
    set((state) => ({
      dataHash: hash(state.data),
      dataTimestamp: Date.now(),
      dataLoaded: true,
      dataLoading: false,
      selectedLocale: state.selectedLocale
        ? state.selectedLocale
        : state.data?.locale[0] ?? {},
    }));
  },
  setLocale: (locale) => {
    set({
      selectedLocale: locale,
    });
  },
  setRefreshData: (refresh) => {
    set({
      dataRefresh: refresh,
    });
  },
  setColorScheme: (colorScheme) => {
    set({
      colorScheme: colorScheme,
    });
  },
  toggleColorScheme: () => {
    set((state) => ({
      colorScheme: state.colorScheme === "dark" ? "light" : "dark",
    }));
  },
});

useStore = persist(useStore, { name: "siteConfig" });

export const useSiteConfig = create(useStore);

export const useToggleColorScheme = () =>
  useSiteConfig((state) => state.toggleColorScheme);
export const useSetRefreshData = () =>
  useSiteConfig((state) => state.setRefreshData);
export const useSetLocale = () => useSiteConfig((state) => state.setLocale);
export const useData = () => useSiteConfig((state) => state.data);
