import { createStyles, Container } from "@mantine/core";
import HeaderLayout from "./Header";
import FooterLayout from "./Footer";

const useStyles = createStyles((theme) => ({
  mainContent: {
    marginTop: 120,
    paddingBottom: 50,

    [theme.fn.smallerThan("sm")]: {
      paddingBottom: 90,
    },
  },
}));

export default function BaseLayout({ title, children }) {
  const { classes } = useStyles();

  document.title = title;
  return (
    <Container fluid={true}>
      <HeaderLayout />
      <Container className={classes.mainContent}>{children}</Container>
      <FooterLayout />
    </Container>
  );
}
