import {
  createStyles,
  Container,
  ThemeIcon,
  Text,
  SimpleGrid,
  Box,
  Stack,
  Button,
  Title,
} from "@mantine/core";
import { Paper, TextInput, Textarea, Group } from "@mantine/core";
import { IconSun, IconPhone, IconMapPin, IconAt } from "@tabler/icons";

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan("sm");
  return {
    root: {
      backgroundColor: "#11103d",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage:
        "linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%), url(/assets/homeback.jpg)",
      paddingTop: theme.spacing.xl * 3,
      paddingBottom: theme.spacing.xl * 3,
      borderRadius: theme.radius.lg - 2,
    },

    inner: {
      display: "flex",
      justifyContent: "space-between",

      [theme.fn.smallerThan("md")]: {
        flexDirection: "column",
      },
    },

    image: {
      [theme.fn.smallerThan("md")]: {
        display: "none",
      },
    },

    content: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      marginRight: theme.spacing.xl * 3,

      [theme.fn.smallerThan("md")]: {
        marginRight: 0,
      },
    },

    title: {
      color: theme.white,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      lineHeight: 1.05,
      maxWidth: 500,
      fontSize: 48,

      [theme.fn.smallerThan("md")]: {
        maxWidth: "100%",
        fontSize: 34,
        lineHeight: 1.15,
      },
    },

    description: {
      color: theme.white,
      opacity: 0.75,
      maxWidth: 500,

      [theme.fn.smallerThan("md")]: {
        maxWidth: "100%",
      },
    },

    control: {
      paddingLeft: 50,
      paddingRight: 50,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 22,

      [theme.fn.smallerThan("md")]: {
        width: "100%",
      },
    },

    icon: {
      marginRight: theme.spacing.md,
      backgroundColor: "transparent",
      color: theme.white,
    },

    wrapper: {
      display: "flex",
      padding: 4,

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    form: {
      boxSizing: "border-box",
      flex: 1,
      padding: theme.spacing.xl,
      paddingLeft: theme.spacing.xl * 2,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    fieldInput: {
      flex: 1,

      "& + &": {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: "flex",

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    contacts: {
      boxSizing: "border-box",
      position: "relative",
      borderRadius: theme.radius.lg - 2,
      background: "linear-gradient(20deg, #11103d 0%, #29abe2 90%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: theme.spacing.xl,
      flex: "0 0 280px",

      [BREAKPOINT]: {
        marginBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
      },
    },

    contactTitle: {
      marginBottom: theme.spacing.xl * 1.5,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },
    contactIconTitle: {
      color: theme.colors[theme.primaryColor][0],
    },

    description: {
      color: theme.white,
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  };
});

function ContactIcon({
  icon: Icon,
  title,
  description,
  variant = "gradient",
  className,
  ...others
}) {
  const { classes, cx } = useStyles({ variant });
  return (
    <div className={cx(classes.wrapper, className)} {...others}>
      <Box mr="md" sx={{ color: "#fff" }}>
        <Icon size={24} sx={{ color: "#fff" }} />
      </Box>

      <div>
        <Text size="xs" className={classes.contactIconTitle}>
          {title}
        </Text>
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  );
}

const MOCKDATA = [
  { title: "Email", description: "info@kuburann.com", icon: IconAt },
  { title: "Address", description: "Hoofddorp, NL", icon: IconMapPin },
];

function ContactIconsList({ data = MOCKDATA, variant }) {
  const items = data.map((item, index) => (
    <ContactIcon key={index} variant={variant} {...item} />
  ));
  return <Stack>{items}</Stack>;
}

const PageHome = () => {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Container size="lg">
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                A global{" "}
                <Text
                  component="span"
                  inherit
                  variant="gradient"
                  gradient={{ from: "pink", to: "yellow" }}
                >
                  Media & Entertainment
                </Text>{" "}
                company
              </Title>

              <Text className={classes.description} mt={30}>
                Our website is under construction, please visit us again soon!
              </Text>
            </div>
          </div>
        </Container>
      </div>
      <br />
      <div>
        <Paper shadow="md" radius="lg">
          <div className={classes.wrapper}>
            <div className={classes.contacts}>
              <Text
                size="lg"
                weight={700}
                className={classes.contactTitle}
                sx={{ color: "#fff" }}
              >
                Contact information
              </Text>
              <ContactIconsList />
            </div>

            <form
              className={classes.form}
              onSubmit={(event) => event.preventDefault()}
            >
              <Text size="lg" weight={700} className={classes.contactTitle}>
                Get in touch
              </Text>

              <div className={classes.fields}>
                <SimpleGrid
                  cols={2}
                  breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                >
                  <TextInput label="Your name" placeholder="Your name" />
                  <TextInput
                    label="Your email"
                    placeholder="Your email"
                    required
                  />
                </SimpleGrid>

                <TextInput
                  mt="md"
                  label="Subject"
                  placeholder="Subject"
                  required
                />

                <Textarea
                  mt="md"
                  label="Your message"
                  placeholder="Please include all relevant information"
                  minRows={3}
                />

                <Group position="right" mt="md">
                  <Button type="submit" className={classes.control}>
                    Send message
                  </Button>
                </Group>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default PageHome;
