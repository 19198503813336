import { useState } from "react";
import {
  createStyles,
  UnstyledButton,
  Menu,
  Image,
  Group,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import Flag from "react-flagkit";
import { useSiteConfig, useSetLocale } from "../store/siteConfig";

const useStyles = createStyles((theme, opened) => ({
  control: {
    width: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
    borderRadius: theme.radius.md,
    transition: "background-color 150ms ease",
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

export function LanguageSelector() {
  const data = useSiteConfig((state) => state.data);
  const setLocale = useSetLocale();
  const selectedLocale = useSiteConfig((state) => state.selectedLocale);
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });

  const items = data?.locale?.map((item) => (
    <Menu.Item
      icon={<Flag country={item.image} size={16} />}
      onClick={() => setLocale(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width={200}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Flag country={selectedLocale.image} size={16} />
          </Group>
          <IconChevronDown size={16} className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown width={200}>{items}</Menu.Dropdown>
    </Menu>
  );
}
