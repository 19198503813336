import { ReactComponent as Logo } from "../assets/img/kuburann.svg";
import { useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { useSiteConfig, useData } from "../store/siteConfig";

const HEADER_HEIGHT = 100;

const useStyles = createStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
    width: "100%",
    backgroundColor: theme.colorScheme === "dark" ? "#000" : "#F6F6F6",
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  logo: {
    ".cls-1": {
      fill: theme.colorScheme === "dark" ? theme.colors.dark[0] : "#11103D",
    },
  },
}));

export default function HeaderLayout() {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState("");
  const { classes, cx } = useStyles();
  const data = useData();
  const items = data?.menu
    ?.find((menu) => menu.location === "header")
    ?.tree?.map((item) => {
      switch (item.type) {
        case "page":
          return (
            <NavLink
              className={cx(classes.link, ({ isActive }) =>
                isActive ? cx(classes.linkActive) : classes.linkActive
              )}
              to={item.destination}
              key={item.id}
            >
              <span>{item.text}</span>
            </NavLink>
          );
        case "link":
          return (
            <a
              key={item.id}
              className={cx(classes.link, ({ isActive }) =>
                isActive ? cx(classes.linkActive) : classes.linkActive
              )}
              href={item.destination}
              target={item.target ?? "_blank"}
            >
              <span>{item.text}</span>
            </a>
          );
      }
    });

  return (
    <Header height={HEADER_HEIGHT} mb={80} className={classes.root}>
      <Container className={classes.header}>
        <NavLink to="/">
          <Logo alt="Kuburann BV" width={150} className={classes.logo} />
        </NavLink>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}
