import {
  createStyles,
  Container,
  Group,
  ActionIcon,
  Text,
  Footer,
} from "@mantine/core";
import { useMantineColorScheme } from "@mantine/core";
import { IconSun, IconMoonStars } from "@tabler/icons";
import { LanguageSelector } from "../components/LanguageSelector";
import SocialIcons from "../components/SocialIcons";
import { useSiteConfig } from "../store/siteConfig";

const useStyles = createStyles((theme) => ({
  footer: {
    position: "fixed",
    left: 0,
    width: "100%",
    zIndex: 99,
    bottom: 0,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    backgroundColor: theme.colorScheme === "dark" ? "#000" : "#F6F6F6",
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.sx,
    paddingBottom: theme.spacing.sx,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function FooterLayout() {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const data = useSiteConfig((state) => state.data);

  return (
    <Footer className={classes.footer}>
      <Container className={classes.inner}>
        <Text color="dimmed" size="sm">
          © {new Date().getFullYear()} {data.company}. {data.footerText}
        </Text>
        <Group spacing={0} className={classes.links} position="right" noWrap>
          <SocialIcons />
          <LanguageSelector />
          <ActionIcon
            onClick={() => toggleColorScheme()}
            size="lg"
            sx={(theme) => ({
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.yellow[4]
                  : theme.colors.blue[6],
            })}
          >
            {colorScheme === "dark" ? (
              <IconSun size={16} />
            ) : (
              <IconMoonStars size={16} />
            )}
          </ActionIcon>
        </Group>
      </Container>
    </Footer>
  );
}
