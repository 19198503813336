import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "@mantine/core";
import { useMemo } from "react";
import { useSiteConfig } from "../store/siteConfig";

export default function SocialIcons() {
  const data = useSiteConfig((state) => state.data);

  const socialIconList = data?.social?.map((network, index) => (
    <ActionIcon
      key={index}
      size="lg"
      component="a"
      href={network.accountLink}
      target="_blank"
    >
      <FontAwesomeIcon size="sm" icon={["fab", network.code]} />
    </ActionIcon>
  ));

  return <>{socialIconList}</>;
}
