import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RouterCombiner from "./routeCombiner";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useSiteConfig, useToggleColorScheme } from "./store/siteConfig";

library.add(fab);

const App = () => {
  const dataTimestamp = useSiteConfig((state) => state.dataTimestamp);
  const dataLoaded = useSiteConfig((state) => state.dataLoaded);
  const dataRefresh = useSiteConfig((state) => state.dataRefresh);
  const getData = useSiteConfig((state) => state.getData);

  const colorScheme = useSiteConfig((state) => state.colorScheme);
  const toggleColorScheme = useToggleColorScheme();

  useEffect(() => {
    if (
      !dataLoaded ||
      dataRefresh ||
      (Date.now() - dataTimestamp) / 1000 > 600
    ) {
      getData();
    }
  }, [dataRefresh, dataLoaded, dataTimestamp]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <BrowserRouter>
          <RouterCombiner />
        </BrowserRouter>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
export default App;
