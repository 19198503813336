import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  Grid,
  Group,
} from "@mantine/core";
import { useLocation, Link } from "react-router-dom";
import JsxParser from "react-jsx-parser";
import NotFound from "./NotFound";
import { useSiteConfig } from "../store/siteConfig";

const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: 28,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: 20,
    },
  },
}));

export default function Content() {
  const { classes } = useStyles();
  const location = useLocation();
  const data = useSiteConfig((state) => state.data);
  const dataLoaded = useSiteConfig((state) => state.dataLoaded);

  const pageDetails = data?.pages?.find(
    (page) => page?.url === location.pathname
  );

  if (!pageDetails && dataLoaded) {
    return <NotFound />;
  }

  document.title = pageDetails?.metaTitle ?? data.name;
  return (
    <Container className={classes.root}>
      <Title className={classes.title}>{pageDetails?.title}</Title>
      <JsxParser
        components={{ Button, Grid, Link }}
        autoCloseVoidElements
        jsx={pageDetails?.value}
      />
    </Container>
  );
}
