import BaseLayout from "./layout/Base";

import PageHome from "./pages/Home";
import PageContent from "./pages/Content";

const Routes = [
  // {
  //     path: "/",
  //     Component: PageHome,
  //     Layout: BaseLayout,
  //     exact: true,
  //     menu: true,
  //     label: "Home",
  //     title: "Kuburann BV - Media, Entertainment & Hosting!",
  // },
  {
    path: "*",
    Component: PageContent,
    Layout: BaseLayout,
    menu: false,
  },
];

export default Routes;
